<template>
  <b-link @click="setMinimize()" v-if="!minimize">
    <b-icon icon="arrows-collapse" title="Minimizar" animation="throb"></b-icon>
  </b-link>
  <b-link @click="setMaximize()" v-else>
    <b-icon icon="arrows-expand" title="Maximizar" animation="throb"></b-icon>
  </b-link>  
</template>

<script>
  export default {
    data() {
      return {
        minimize: false,
      }
    },    
    methods: {      
      setMinimize() {                
        const arrModalOpen = document.getElementsByClassName('modal-body');        
        const lastModalOpen = arrModalOpen.length - 1;        

        if(lastModalOpen != undefined) {
          document.getElementsByClassName('modal-body')[lastModalOpen].style.display = 'none'
          document.getElementsByClassName('modal-footer')[lastModalOpen].style.display = 'none'  

          this.minimize = true 
        }       
      },
      setMaximize() {        
        const arrModalOpen = document.getElementsByClassName('modal-body');
        const lastModalOpen = arrModalOpen.length - 1;
        
        if(lastModalOpen != undefined) {
          document.getElementsByClassName('modal-body')[lastModalOpen].style.display = 'block'
          document.getElementsByClassName('modal-footer')[lastModalOpen].style.display = 'flex'  

          this.minimize = false
        }
      },
    }
  }
</script>